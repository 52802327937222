'use client';

import React, { useState, useEffect, useRef } from 'react';
import { useGetProductsQuery } from '@/app/services/listing/product/ProductListingService';
import ProductCard from '@/app/components/cards/ProductCard';
import SkeletonVerticalCard from '@/app/components/cards/SkeletonVerticalCard';
import NotFound from '@/app/components/NotFound';
import ProductDetail from '@/app/components/modals/product/DetailModal';
import { useDispatch } from 'react-redux';
import { setSelectedImage } from '@/app/providers/slices/AppSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoePrints, faMagnifyingGlassPlus, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';

interface Image {
  url: string;
}

interface Seller {
  username: string;
}

interface City {
  name: string;
}

interface IProduct {
  id: number;
  name: string;
  price: string;
  images: Image[];
  seller: Seller;
  slug: string;
  city?: City;
  createdAt: string;
}

interface PaginationMeta {
  pagination: {
    page: number;
    pageCount: number;
    pageSize: number;
    total: number;
  };
}

interface ProductsResponse {
  data: IProduct[];
  meta: PaginationMeta;
}

const PRODUCTS_PER_VIEW = 4;

export default function UnisexShoesDeals() {
  const [allProducts, setAllProducts] = useState<IProduct[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();

  const { data: products, isLoading } = useGetProductsQuery<{
    data: ProductsResponse;
    isLoading: boolean;
    isError: boolean;
  }>({
    city: '',
    productType: 'Sneakers',
    page: 1,
  });

  useEffect(() => {
    if (products?.data) {
      // Create a shallow copy of the products array before sorting
      const sortedProducts = [...products.data].sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      })
      setAllProducts(sortedProducts);
    }
  }, [products]);


  const handleOpen = (product: IProduct): void => {
    setSelectedProduct(product);
    setIsOpen(true);
    dispatch(setSelectedImage(product.images[0]));
  };

  const handleClose = (): void => {
    setIsOpen(false);
    setSelectedProduct(null);
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  return (
    <div className="px-4 py-6">
      <div className="flex justify-between items-center mb-6 p-2 bg-red-500 rounded-lg text-white">
        <h2 className="text-2xl font-bold flex items-center gap-2">
          <FontAwesomeIcon icon={faShoePrints} className="text-white" />
          <span>Sneakers</span>
        </h2>

        <Link href={'/products-listing?productCategory=Sneakers&location='}
          className='inline-flex items-center px-4 py-2 text-[12px] bg-gray-900 text-white hover:bg-gray-800 rounded-lg transition-colors '>
          <span className='mr-2'>Voir Plus</span>
          <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
        </Link>
      </div>

      {isLoading && allProducts.length === 0 ? (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
          {Array.from({ length: PRODUCTS_PER_VIEW }, (_, index) => (
            <SkeletonVerticalCard key={index} />
          ))}
        </div>
      ) : allProducts.length > 0 ? (
        <div className="relative">
          {/* Scroll Container */}
          <div className="overflow-x-auto scroll-smooth scrollbar-hide" ref={scrollContainerRef}>
            <div className="flex gap-4">
              {allProducts.map((item) => (
                <div key={item.id} className="w-1/2 md:w-1/4 flex-shrink-0">
                  <ProductCard
                    seller={item.seller.username}
                    images={item.images}
                    name={item.name}
                    price={item.price}
                    slug={item.slug}
                    city={item?.city?.name || ''}
                    featured={false}
                    onClick={() => handleOpen(item)}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Navigation Buttons */}
          <button
            onClick={scrollLeft}
            className="absolute left-0 top-1/2 -translate-y-1/2 bg-gray-800/90 hover:bg-gray-700 text-white rounded-full p-3 transition-colors shadow-lg"
            aria-label="Previous products"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>

          <button
            onClick={scrollRight}
            className="absolute right-0 top-1/2 -translate-y-1/2 bg-gray-800/90 hover:bg-gray-700 text-white rounded-full p-3 transition-colors shadow-lg"
            aria-label="Next products"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      ) : (
        <NotFound />
      )}

      {selectedProduct && (
        <ProductDetail
          isOpen={isOpen}
          onClose={handleClose}
          product={selectedProduct}
        />
      )}
    </div>
  );
}