'use client';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from "@heroui/react";
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { store } from '@/redux/store';
import UseRegisterModal from '@/app/hooks/useRegisterModal';
import Link from 'next/link';

const MainBannerVendre: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [user, setUser] = useState<any | null>(null);
    const [isAuthenticated, setAuthenticated] = useState(false);
    const registerModal = UseRegisterModal();

    useEffect(() => {
        const userData = store.getState()?.authSlice?.user ?? {};
        setUser(userData);
        setAuthenticated(!!userData?.email);
    }, []);

    const handleAddClick = () => {
        if (isAuthenticated) {
            onOpen();
        } else {
            registerModal.onOpen();
        }
    };

    return (
        <section className="relative text-white bg-white py-16"
            style={{ backgroundImage: "url('/images/backgroundFame2.png')" }}>
            <div className="container mx-auto px-4">
                <div className="flex flex-col md:flex-row items-center justify-between gap-8">

                    {/* Left Image Column */}
                    <div className="w-full md:w-1/4 flex items-center justify-center hidden md:flex">
                        <Image
                            src="/images/leftIcon.png"
                            alt="Selling items"
                            width={300}
                            height={300}
                            className="w-auto h-auto object-contain"
                        />
                    </div>

                    {/* Center Content Column */}
                    <div className="w-full md:w-2/4 text-center px-6">
                        <div className="max-w-xl mx-auto">
                            <h2 className="text-3xl font-bold mb-6">
                                C'est le moment parfait pour vendre vos articles !
                            </h2>
                            <p className="text-lg mb-8">
                                Que ce soit des objets neufs ou d'occasion, mettez-les en vente dès aujourd'hui et profitez de l'opportunité.
                            </p>
                            <Link href={'/marchant-registration'}
                                className="inline-block bg-black text-white font-semibold py-3 px-6 rounded-md shadow-lg hover:bg-gray-800 transition-all"
                            >
                                <FontAwesomeIcon icon={faCartShopping} className="mr-2" />
                                Vendre maintenant
                            </Link>
                        </div>
                    </div>

                    {/* Right Image Column */}
                    <div className="w-full md:w-1/4 flex items-center justify-center">
                        <Image
                            src="/images/rightIcon.png"
                            alt="Shopping experience"
                            width={500}
                            height={500}
                            className="w-auto h-auto object-contain"
                            priority
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MainBannerVendre;