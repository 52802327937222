'use client';

import React, { useState, useEffect, useCallback } from 'react';
import { useGetProductsQuery } from '@/app/services/listing/product/ProductListingService';
import ProductCard from '@/app/components/cards/ProductCard';
import SkeletonVerticalCard from '@/app/components/cards/SkeletonVerticalCard';
import NotFound from '@/app/components/NotFound';
import ProductDetail from '@/app/components/modals/product/DetailModal';
import { useDispatch } from 'react-redux';
import { setSelectedImage } from '@/app/providers/slices/AppSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from "@heroui/react";
import Link from 'next/link';

interface Image {
  url: string;
}

interface Seller {
  username: string;
}

interface City {
  name: string;
}

interface IProduct {
  id: number;
  name: string;
  price: string;
  images: Image[];
  seller: Seller;
  featured: boolean;
  slug: string;
  city?: any;  // Handle mixed structures
}

interface PaginationMeta {
  pagination: {
    page: number;
    pageCount: number;
    pageSize: number;
    total: number;
  };
}

interface ProductsResponse {
  data: IProduct[];
  meta: PaginationMeta;
}

interface ProductQueryParams {
  city: string;
  productType: string;
  page: number;
}

export default function TopDeals() {
  const [allProducts, setAllProducts] = useState<IProduct[]>([]);
  const [displayedProducts, setDisplayedProducts] = useState<IProduct[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [hasMorePages, setHasMorePages] = useState<boolean>(true);


  const { data: products, isLoading, isError } = useGetProductsQuery<{
    data: ProductsResponse;
    isLoading: boolean;
    isError: boolean;
  }>({
    city: '',
    productType: '',
    page: currentPage,
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  const dispatch = useDispatch();

  // Update product list and displayed products when new data is available
  useEffect(() => {
    if (products?.data) {
      // Update all products
      setAllProducts((prev) => {
        const productMap = new Map(prev.map((product) => [product.id, product]));
        products.data.forEach((product) => productMap.set(product.id, product));
        return Array.from(productMap.values());
      });

      // Update pagination status
      if (products?.meta?.pagination) {
        const { page, pageCount } = products.meta.pagination;
        setHasMorePages(page < pageCount);
      } else {
        setHasMorePages(false);
      }

      setIsLoadingMore(false);
    }
  }, [products]);

  // Update displayed products whenever allProducts changes
  useEffect(() => {
    const featuredProducts = allProducts.filter((product) => product.featured);
    setDisplayedProducts(featuredProducts);
  }, [allProducts]);

  // Load next page when scroll near bottom or if initial featured products are too few
  const loadNextPage = useCallback((): void => {
    if (hasMorePages && !isLoadingMore) {
      setIsLoadingMore(true);
      setCurrentPage((prev) => prev + 1);
    }
  }, [hasMorePages, isLoadingMore]);

  // Auto-load more if we have too few featured products
  useEffect(() => {
    const MIN_FEATURED_PRODUCTS = 20;
    if (displayedProducts.length < MIN_FEATURED_PRODUCTS && hasMorePages && !isLoading && !isLoadingMore) {
      loadNextPage();
    }
  }, [displayedProducts.length, hasMorePages, isLoading, isLoadingMore, loadNextPage]);

  const handleOpen = (product: IProduct): void => {
    setSelectedProduct(product);
    setIsOpen(true);
    dispatch(setSelectedImage(product.images[0]));
  };

  const handleClose = (): void => {
    setIsOpen(false);
    setSelectedProduct(null);
  };

  const topDealItems = displayedProducts.map((item) => (
    <ProductCard
      seller={item.seller.username}
      key={item.id}
      images={item.images}
      name={item.name}
      price={item.price}
      slug={item.slug}
      city={item?.city?.name}
      featured={item.featured}
      onClick={() => handleOpen(item)}
    />
  ));

  return (
    <div className="px-4 py-6">
      <h2 className="text-3xl font-bold mb-4">
        <FontAwesomeIcon icon={faFire} /> Top Deals
      </h2>

      {isLoading && displayedProducts.length === 0 ? (
        <div className="grid md:grid-cols-4 gap-4 mb-8 lg:grid-cols-4 xl:grid-cols-4 grid-cols-2">
          {Array.from({ length: 8 }, (_, index) => (
            <SkeletonVerticalCard key={index} />
          ))}
        </div>
      ) : displayedProducts.length > 0 ? (
        <>
          <div className="grid md:grid-cols-4 gap-4 mb-8 lg:grid-cols-5 xl:grid-cols-5 grid-cols-2">
            {topDealItems}
          </div>
          <div className="flex justify-center items-center  ">

            <Link href={'/products-listing?productCategory=&location='}
              className='inline-flex items-center px-4 py-2 text-[16px] bg-gray-900 text-white hover:bg-gray-800 rounded-lg transition-colors '>
              <span className='mr-2'>Voir Plus</span>
              <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
            </Link>
          </div>

          {isLoadingMore && (
            <div className="flex justify-center my-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          )}
        </>
      ) : (
        <NotFound />
      )}

      {selectedProduct && (
        <ProductDetail
          isOpen={isOpen}
          onClose={handleClose}
          product={selectedProduct}
        />
      )}
    </div>
  );
}